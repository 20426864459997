import React, { useEffect, useState } from "react";
import "../style/RouteInfo.css";
import Axios from "axios";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import { useParams } from "react-router";

const RouteInfo = () => {
  //-------------------------------------------------------------------------------
  // state
  //-------------------------------------------------------------------------------
  const { id } = useParams();
  const [routeName, setRouteName] = useState("");
  const [description, setDescription] = useState("");
  const [stoneQuality, setStoneQuality] = useState("");
  const [start, setStart] = useState("");
  const [descent, setDescent] = useState("");
  const [material, setMaterial] = useState("");
  const [routeSketch, setRouteSketch] = useState("");
  const [routeWallImage, setRouteWallImage] = useState("");
  const [descentDifficulty, setDescentDifficulty] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [height, setHeight] = useState("");
  const [ropes, setRopes] = useState("");
  const [routeImages, setRouteImage] = useState([
    { url: "./images/img-2.jpg" },
    { url: "./images/img-1.jpg" },
  ]);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  //-------------------------------------------------------------------------------
  // slide styling
  //-------------------------------------------------------------------------------
  useEffect(() => {
    const handleWindowResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  function getHeight(innerWidth) {
    if (innerWidth <= 650) return "250px";
    if (innerWidth <= 700) return "300px";
    if (innerWidth <= 1200) return "400px";
    if (innerWidth <= 1400) return "600px";
    return "700px";
  }

  const divStyle = {
    display: "block",
    margin: "auto",
    backgroundSize: "cover",
    height: getHeight(innerWidth),
    objectPosition: "center",
    maxWidth: "100%",
    width: "auto",
    maxHeight: "700px",
    backgroundPosition: "center",
  };

  //-------------------------------------------------------------------------------
  // locale function
  //-------------------------------------------------------------------------------

  const createObjectArray = (imageRoute) => {
    const initialValue = [];
    const array = imageRoute.split(" ");
    const arrayLength = array.length;
    for (var i = 0; i < arrayLength; i++) {
      initialValue.push({ url: array[i] });
    }
    return initialValue;
  };

  //-------------------------------------------------------------------------------
  // imports
  //-------------------------------------------------------------------------------

  useEffect(() => {
    Axios.get("https://barglen-project.onrender.com/api/routes", {
      params: { routeName: id },
    }).then((response) => {
      const allData = response.data;
      setRouteName(allData[0].routeName);
      setDescription(allData[0].description);
      setStoneQuality(allData[0].stoneQuality);
      setStart(allData[0].start);
      setDescent(allData[0].descent);
      setMaterial(allData[0].material);
      setRouteSketch(allData[0].routeSketch);
      setRouteWallImage(allData[0].routeWallPicture);
      setDescentDifficulty(allData[0].descentDifficulty);
      setDifficulty(allData[0].difficulty);
      setHeight(allData[0].height);
      setRopes(allData[0].rope);
      setRouteImage(createObjectArray(allData[0].routeImages));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="routeInfoContainer">
      <h1>{routeName}</h1>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>
                <i className="fa-solid fa-signal"></i> Schwierigkeit
              </th>
              <th>
                <i className="fa-solid fa-arrow-trend-up"></i> Kletterlänge
              </th>
              <th>
                <i className="fa-solid fa-arrow-up-1-9"></i> Seillängen
              </th>
              <th>
                <img
                  src="/images/bolt.ico"
                  className="climbinBolt"
                  alt="climbing Bolt"
                />{" "}
                Absicherung
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{difficulty}</td>
              <td>{height}</td>
              <td>{ropes}</td>
              <td>{descentDifficulty}</td>
            </tr>
          </tbody>
        </table>
        <br />
      </div>
      <div className="mobileWrapper">
        <div className="wrapper">
          <div className="text">
            <div className="description">
              <p>{description}</p>
              <h5>Felsqualität:</h5>
              <p>{stoneQuality} </p>
              <h5>Einstieg:</h5>
              <p>{start}</p>
              <h5>Abstieg:</h5>
              <p>{descent}</p>
              <h5>Material:</h5>
              <p>{material}</p>
            </div>
          </div>
          <div className="pictureOne">
            <img
              src={routeSketch}
              className="routeSketch"
              alt="route Sketch"
            ></img>
          </div>
          <div className="pictureTwo">
            <img
              src={routeWallImage}
              className="routeWallImage"
              alt="WallImage"
            ></img>
          </div>
          <div id="slide-container">
            <Slide>
              {routeImages.map((slideImage, index) => (
                <div key={index}>
                  <div
                    style={{
                      ...divStyle,
                      backgroundImage: `url(${slideImage.url})`,
                    }}
                  ></div>
                </div>
              ))}
            </Slide>

            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteInfo;
