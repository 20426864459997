import format from "date-fns/format";
import swal from "sweetalert";
//import getDay from "date-fns/getDay";
//import parse from "date-fns/parse";
//import startOfWeek from "date-fns/startOfWeek";
import React, {Fragment, useEffect, useState} from "react";
//import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../App.css";
import "../style/Table.css";
import EditableRow from "./EditableRow";
import ReadOnlyRow from "./ReadOnlyRow";
import Axios from "axios";

//-------------------------------------------------------------------------------
// conf for react-Calendar
//-------------------------------------------------------------------------------

/*const locales = {
  "en-Us": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});*/

function DateCalendar() {
    //-------------------------------------------------------------------------------
    // state
    //-------------------------------------------------------------------------------
    const [allEvents, setAllEvents] = useState([]);
    //const [calendarEvents, setCalendarEvents] = useState([]);

    const [addFormData, setAddFormData] = useState({
        title: "",
        startDate: "",
        endDate: "",
    });

    const [editDataId, setEditDataId] = useState(null);
    const [editFormData, setEditFormData] = useState({
        startDate: "",
        endDate: "",
        title: "",
    });

    useEffect(() => {
        Axios.get("https://barglen-project.onrender.com/api/calendar").then(
            (response) => {
                setAllEvents(response.data);
            }
        );
    }, []);

    useEffect(() => {
        Axios.get("https://barglen-project.onrender.com/api/calendar").then(
            (response) => {
                //setCalendarEvents(response.data);
            }
        );
    }, []);

    //-------------------------------------------------------------------------------
    // locale function
    //-------------------------------------------------------------------------------

    const changeDateFormat = (date) => {
        let formattedDate =
            date.substring(6, 10) +
            "-" +
            date.substring(3, 5) +
            "-" +
            date.substring(0, 2);
        formattedDate = new Date(formattedDate);
        return formattedDate;
    };

    /*const changeDateFormatISO = (date) => {
      let formattedDate =
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4);
      return formattedDate;
    };

    function addOneDay(date = new Date().toISOString()) {
      date.setDate(date.getDate() + 2);

      const newDate = changeDateFormatISO(date.toISOString());
      return newDate;
    }

    function changeCalendarEvents() {
      for (let i = 0; i < allEvents.length; i++) {
        const startDate = allEvents[i].startDate;
        let normallEndDate = allEvents[i].endDate;
        //normallEndDate = normallEndDate.replace(/\./g, "/");
        var dateParts = normallEndDate.split(".");
        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        const endDate = addOneDay(new Date(dateObject));
        const startDay = startDate.substring(0, 2);
        const endDay = endDate.substring(0, 2);
        const startMonth = startDate.substring(3, 5);
        const endMonth = endDate.substring(3, 5);
        const startYear = startDate.substring(6, 10);
        const endYear = endDate.substring(6, 10);
        const formattedEndDate = endYear + ", " + endMonth + ", " + endDay;
        const formattedStartDate =
          startYear + ", " + startMonth + ", " + startDay;
        calendarEvents[i].endDate = formattedEndDate;
        calendarEvents[i].startDate = formattedStartDate;
      }
      return calendarEvents;
    }*/

    //-------------------------------------------------------------------------------
    // handle ADD
    //-------------------------------------------------------------------------------

    function handleAddEvent(event) {
        event.preventDefault();
        for (let i = 0; i < allEvents.length; i++) {
            const startDate = allEvents[i].startDate;
            const endDate = allEvents[i].endDate;

            let d1 = new Date(changeDateFormat(startDate));
            let d2 = new Date(addFormData.startDate);
            let d3 = new Date(changeDateFormat(endDate));
            let d4 = new Date(addFormData.endDate);

            if (
                (d1.toDateString() <= d2.toDateString() &&
                    d2.toDateString() <= d3.toDateString()) ||
                (d1.toDateString() <= d4.toDateString() &&
                    d4.toDateString() <= d3.toDateString())
            ) {
                swal("Date occupied or in a wrong order");
                return;
            }
        }
        let startDate = new Date(addFormData.startDate);
        let endDate = new Date(addFormData.endDate);
        const newFormatedData = {
            title: addFormData.title,
            startDate: format(startDate, "dd.MM.yyyy"),
            endDate: format(endDate, "dd.MM.yyyy"),
        };
        Axios.post(
            "https://barglen-project.onrender.com/api/calendar",
            newFormatedData
        );
        setAllEvents([...allEvents, newFormatedData]);
    }

    //-------------------------------------------------------------------------------
    // handle EDIT
    //-------------------------------------------------------------------------------

    const handleEditFormChangeEndDate = (date) => {
        const newFormData = {...editFormData};
        const endDate = format(date, "dd.MM.yyyy");
        newFormData["endDate"] = endDate;
        setEditFormData(newFormData);
    };

    const handleEditFormChangeStartDate = (date) => {
        const newFormData = {...editFormData};
        const startDate = format(date, "dd.MM.yyyy");
        newFormData["startDate"] = startDate;
        setEditFormData(newFormData);
    };

    const handleEditFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = {...editFormData};
        newFormData[fieldName] = fieldValue;

        setEditFormData(newFormData);
    };

    const handleEditSave = (event) => {
        event.preventDefault();

        /*setCalendarEvents([
          ...allEvents,
          {
            startDate: "",
            endDate: "",
            title: "",
          },
        ]);*/

        let dataWithoutEdited = allEvents;
        dataWithoutEdited = dataWithoutEdited.filter(
            (data) => data.id !== editFormData.id
        );

        for (let i = 0; i < dataWithoutEdited.length; i++) {
            const startDate = dataWithoutEdited[i].startDate;
            const endDate = dataWithoutEdited[i].endDate;

            let d1 = new Date(changeDateFormat(startDate));
            let d2 = new Date(changeDateFormat(editFormData.startDate));
            let d3 = new Date(changeDateFormat(endDate));
            let d4 = new Date(changeDateFormat(editFormData.endDate));

            if (
                (d1.toISOString() <= d2.toISOString() &&
                    d2.toISOString() <= d3.toISOString()) ||
                (d1.toISOString() <= d4.toISOString() &&
                    d4.toISOString() <= d3.toISOString())
            ) {
                swal("Date occupied");
                return;
            }
        }

        const editedItem = {
            id: editFormData.id,
            startDate: editFormData.startDate,
            endDate: editFormData.endDate,
            title: editFormData.title,
        };

        try {
            Axios.patch("https://barglen-project.onrender.com/api/calendar", {
                id: editFormData.id,
                startDate: editedItem.startDate,
                endDate: editedItem.endDate,
                title: editedItem.title,
            });
            setAllEvents([
                ...allEvents,
                {
                    startDate: addFormData.startDate,
                    endDate: addFormData.endDate,
                    title: addFormData.title,
                },
            ]);
        } catch (error) {
            console.error(error);
        }

        const newItems = [...allEvents];
        const index = allEvents.findIndex((item) => item.id === editDataId);
        newItems[index] = editedItem;
        setAllEvents(newItems);
        //setCalendarEvents(allEvents);
        setEditDataId(null);
    };

    const handleEditClick = (event, data) => {
        event.preventDefault();
        setEditDataId(data.id);

        const formValues = {
            startDate: data.startDate,
            endDate: data.endDate,
            title: data.title,
            id: data.id,
        };

        setEditFormData(formValues);
    };

    const handleCancelClick = () => {
        setEditDataId(null);
    };

    //-------------------------------------------------------------------------------
    // handle DELETE
    //-------------------------------------------------------------------------------

    const handleDeleteClick = (id) => {
        try {
            const newItems = [...allEvents];

            const index = allEvents.findIndex((data) => data.id === id);
            Axios.delete("https://barglen-project.onrender.com/api/calendar", {
                headers: {},
                data: {
                    id: id,
                }
            });
            newItems.splice(index, 1);
            setAllEvents(newItems);
            //setCalendarEvents(allEvents);
        } catch (error) {
            console.error(error);
        }
    };

    //changeCalendarEvents();
    return (
        <div className="table-container">
            <h1> Calendar </h1>
            <form onSubmit={handleEditSave} autoComplete="off">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                        <th scope="col">Title</th>
                        <th scope="col">Edit</th>
                    </tr>
                    </thead>
                    <tbody className="table-group-divider">
                    {allEvents.map((data) => (
                        <Fragment>
                            {editDataId === data.id ? (
                                <EditableRow
                                    editFormData={editFormData}
                                    handleEditFormChange={handleEditFormChange}
                                    handleCancelClick={handleCancelClick}
                                    handleEditFormChangeEndDate={handleEditFormChangeEndDate}
                                    handleEditFormChangeStartDate={
                                        handleEditFormChangeStartDate
                                    }
                                />
                            ) : (
                                <ReadOnlyRow
                                    data={data}
                                    handleEditClick={handleEditClick}
                                    handleDeleteClick={handleDeleteClick}
                                />
                            )}
                        </Fragment>
                    ))}
                    </tbody>
                </table>
            </form>
            <h2> Add New Event </h2>
            <form onSubmit={handleAddEvent} autoComplete="off">
                <div className="row g-3">
                    <div className="col-6">
                        <label htmlFor="addTitle" className="form-label form-label-lg">
                            Add Title
                        </label>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="addTitle"
                            required="required"
                            value={addFormData.title}
                            onChange={(e) =>
                                setAddFormData({...addFormData, title: e.target.value})
                            }
                        />
                    </div>
                    <div className="col-3">
                        <label htmlFor="startDate" className="form-label form-label-lg">
                            Start Date
                        </label>
                        <DatePicker
                            wrapperClassName="datePicker"
                            className="form-control"
                            id="startDate"
                            required
                            selected={addFormData.startDate}
                            dateFormat="dd.MM.yyyy"
                            onChange={(startDate) =>
                                setAddFormData({...addFormData, startDate})
                            }
                        />
                    </div>
                    <div className="col-3">
                        <label htmlFor="endDate" className="form-label form-label-lg">
                            End Date
                        </label>
                        <DatePicker
                            wrapperClassName="datePicker"
                            id="endDate"
                            className="form-control datePicker"
                            required
                            selected={addFormData.endDate}
                            dateFormat="dd.MM.yyyy"
                            onChange={(endDate) =>
                                setAddFormData({...addFormData, endDate})
                            }
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-secondary">
                    Add Event
                </button>
            </form>
            {/*
        <Calendar
          localizer={localizer}
          events={calendarEvents}
          startAccessor="startDate"
          endAccessor="endDate"
          style={{ height: 500, margin: "50px" }}
            />
      */}
        </div>
    );
}

export default DateCalendar;
