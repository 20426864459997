import React from "react";
import "../style/Info.css";

function Info() {
  return (
    <div className="info">
      <div className="flexC1">
        <div className="introduction">
          <h2>Bonistock - Barglen</h2>
          <p>
            Das beim Melchsee beginnende Felsband mit dem beliebten
            Klettergarten Bonistock setzt sich jenseits der Chringen fort bis
            zur Barglen, wo es markant abbricht. In dieser vier Kilometer langen
            Wandflucht gibt es für Kletterer neben unattraktiven Zonen auch
            mehrere bis gegen 200 m hohe Abschnitte mit vorzüglichem
            Hochgebirgskalk. 2002 entstand im attraktivsten darunter der
            Klettergarten Barglen mit einem halben Dutzend 1 – 2 SL Routen,
            inzwischen komplettiert durch weitere Linien.
            <br />
            Ab 2013 wurden dann die ersten längeren Touren eingebohrt. Bis dato
            zählt man in der Hohmad – Barglen Kette 15 MSL von 4 – 7 SL an
            herrlich rauem, unabgegriffenem Fels. Einige darunter sind wahre
            Perlen, allen voran der Burner, der trotz seiner bescheidenen Länge
            zu den schönsten Sportklettereien der Zentralschweiz gehört.
          </p>
          <table className="table">
            <tbody>
              <tr>
                <th scope="row">Exposition</th>
                <td>Südost</td>
              </tr>
              <tr>
                <th scope="row">Jahreszeit</th>
                <td>
                  <>Frühling </>
                  <>Sommer </>
                  <>Herbst </>
                  <>Winter </>
                </td>
              </tr>
              <tr>
                <th scope="row">Absicherung</th>
                <td>Gut bis sehr gut </td>
              </tr>
              <tr>
                <th scope="row">Material</th>
                <td>(2x) 50 m Seil, 9 - 12 Express</td>
              </tr>
              <tr>
                <th scope="row">Felsqualität</th>
                <td>Gut bis ausgezeichnet</td>
              </tr>
              <tr>
                <th scope="row">Kletterstil</th>
                <td>
                  Steile Wandkletterei an Leisten, Auflegern und Tropflöchern,
                  eher selten Plattenpassagen, nur ganz vereinzelt Risse oder
                  Verschneidungen
                </td>
              </tr>
              <tr>
                <th scope="row">Abstieg</th>
                <td>Abseilen; z.T. Fussabstieg möglich</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table">
          <br />
          <figure>
            <img
              src="./images/Tannalp-Barglen.jpg"
              className="figure-img img-fluid rounded"
              alt="Zustieg"
            />
            <figcaption className="figure-caption text-end">
              Tannalp und Barglen
            </figcaption>
          </figure>
        </div>
      </div>
      <div className="flexGap"></div>
      <div className="flexC2">
        <div className="ascentImg">
          <figure className="figure">
            <img
              src="./images/Zustieg-Barglen.jpg"
              className="figure-img img-fluid rounded"
              alt="Zustieg"
            />
            <figcaption className="figure-caption text-end">
              Grundkarte für den Zustieg
            </figcaption>
          </figure>
        </div>
        <div className="Ascend">
          <h2>Zustieg</h2>
          <p>
            Der bequemste Zustieg zu den Kletterrouten am Chli Hohmad und an der
            Barglen erfolgt via Tannalp, welche mit dem Frutt-Zug ab der alten
            Bergstation auf Melchsee-Frutt in einer halben Stunde Fahrzeit
            erreichbar ist (Abfahrt jede volle Stunde ab 9 Uhr von ca. Mitte
            Juni - Mitte Oktober). Von der Tannalp folgt man dem Wanderweg in
            nördlicher Richtung hoch auf eine kleine Ebene, welche man weglos
            nordwärts quert, bis man auf die Wegspuren unterhalb von
            Murmoltereneggen trifft. Diesen folgt man noch einige Meter in nord-
            östlicher Richtung bis zu einer auffälligen, Richtung Wand
            ansteigenden Wiese (Steinmann). Ab hier weisen zahlreiche
            Steinmänner den allmählich steiler werdenden Weg hoch bis zum
            höchstgelegenen grossen Felsblock, wo die Wiese mehr und mehr in
            Geröll übergeht. Durch die Schutthalden auf Weglein schräg hoch zum
            Wandfuss, welchem man rechts haltend zu Biwakplatz folgt (Zeit ab
            Tannalp 1 h).
            <br />
            Vom Biwakplatz aus erreicht man auf Weglein dem Wandfuss entlang in
            wenigen Schritten R5 „Namaycush“ und R6 „54-46“, die andern Routen
            und den Klettergarten in 5 - 10 min
            <br />
            <br />
            Alternative mit Bike
            <br />
            Auf der Fahrstrasse bis zur Staumauer des Tannensees, von dort
            steiler ansteigend auf Asphaltstrasse hoch bis zu Wegweiser an derem
            Ende (Bike-Depot; Zeit ab Melchsee-Frutt ca. 3/4 h).
            <br />
            Von hier in nordöstlicher Richtung zur Wasserfassung und Pfadspuren
            folgend durch die Hochebene (2-3 Markierungsstangen). Weiter auf
            Trittspuren bis zur nach links hochziehenden Wiese wie oben
            beschrieben.
            <br />
            Zeit vom Bike-Depot ca. 50 min.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Info;
