import React from "react";
import "../../App.css";

let style = {
  color: "black",
};

function ReadOnlyRow({ item, handleEditClick, handleDeleteClick }) {
  if ((item.inUse === 1 || item.inUse === true)) {
    style = {
      color: "red",
    };
  } else {
    style = {
      color: "black",
    };
  }
  return (
    <tr key={item.id} style={style}>
      <td>{item.item}</td>
      <td>{item.amount}</td>
      <td>
        <input type="checkbox" disabled defaultChecked={item.inUse} />
      </td>
      <td>
        <i
          className="fa-solid fa-pen-to-square space"
          onClick={(event) => handleEditClick(event, item)}
        ></i>
        <i
          className="fa-solid fa-trash"
          onClick={() => handleDeleteClick(item.id)}
        ></i>
      </td>
    </tr>
  );
}

export default ReadOnlyRow;
