import React from "react";
import DatePicker from "react-datepicker";
import "../style/Table.css";

const changeDateFormat = (date) => {
  let formattedDate = date.substring(6, 10) + "-" + (date.substring(3, 5) ) + "-" + date.substring(0, 2)
  formattedDate = new Date(formattedDate)
  return formattedDate
}
const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleEditFormChangeEndDate,
  handleEditFormChangeStartDate,
  handleCancelClick,
  }) => {
  return (
    <tr >
      <td>
        <DatePicker
          wrapperClassName="datePicker"
          id="startDate"
          name="startDate"
          className="form-control datePicker"
          required
          selected={changeDateFormat(editFormData.startDate)}
          dateFormat="dd.MM.yyyy"
          onChange={startDate => handleEditFormChangeStartDate(startDate)}
          />
      </td>
      <td>
        <DatePicker
          wrapperClassName="datePicker"
          id="endDate"
          name="endDate"
          className="form-control datePicker"
          required
          dateFormat="dd.MM.yyyy"
          onChange={endDate => handleEditFormChangeEndDate(endDate)}
          selected={changeDateFormat(editFormData.endDate)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          required="required"
          name="title"
          placeholder="Enter a title..."
          value={editFormData.title}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <button type="submit" className="btnEdit">
          <i className="fa-solid fa-check space"> </i>
        </button>
        <button type="button" onClick={handleCancelClick} className="btnEdit">
          <i className="fa-solid fa-xmark"> </i>
        </button>
      </td>
    </tr>
  );
};

export default EditableRow;

