import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "../style/Wallpicture.css";

function Wallpicture() {
  return (
    <>
      <img
        className="overview"
        src="./images/Barglen_Übersicht.jpg"
        alt="Toppo"
      />
      <div className="container Routes">
        <table className="table">
          <tbody>
            <tr>
              <th scope="row">01</th>
              <Link to="/route-info/Namaycush">
                <td>Namaycush</td>
              </Link>
              <td>6b (6a obl.)</td>
              <td>140 m</td>
              <td>5 SL</td>
              {
                //<td>S. Speck</td>
              }
            </tr>
            <tr>
              <th scope="row">02</th>
              <Link to="/route-info/54-46">
                <td>54-46</td>
              </Link>
              <td>6b+ (5c+, 2pa)</td>
              <td>160 m</td>
              <td>5 SL</td>
              {
                //<td>O. Keiser, S. Speck</td>
              }
            </tr>
            <tr>
              <th scope="row">03</th>
              <Link to="/route-info/Näbelfalter">
                <td>Näbelfalter</td>
              </Link>
              <td>6c</td>
              <td>200 m</td>
              <td>6 SL</td>
              {
                //<td> U. Lötscher, K. Schmid</td>
              }
            </tr>
            <tr>
              <th scope="row">04</th>
              <Link to="/route-info/Chalchprinzässin">
                <td>Chalchprinzässin</td>
              </Link>
              <td>6b (6a+ obl.)</td>
              <td>190 m</td>
              <td>5 SL</td>
              {
                //<td>U. Lötscher, K. Schmid</td>
              }
            </tr>
            <tr>
              <th scope="row">05</th>
              <Link to="/route-info/Miss Kalzit">
                <td>Miss Kalzit</td>
              </Link>
              <td>6b (6b obl.)</td>
              <td>200 m</td>
              <td>6 SL</td>
              {
                //<td>U. Lötscher, K. Schmid</td>
              }
            </tr>
            <tr>
              <th scope="row">06</th>
              <Link to="/route-info/Tschent...">
                <td>Tschent...</td>
              </Link>
              <td>6c (6b obl.) </td>
              <td>170 m</td>
              <td>7 SL</td>
              {
                //<td>S. Speck</td>
              }
            </tr>
            <tr>
              <th scope="row">07</th>
              <td>Bloody end</td>
              <td>7b</td>
              <td>? m</td>
              <td>? SL</td>
              {
                //<td></td>
              }
            </tr>
            <tr>
              <th scope="row">08</th>
              <Link to="/route-info/Dräck im Gsecht">
                <td>Dräck im Gsecht</td>
              </Link>
              <td>6b (6b obl.)</td>
              <td>190 m</td>
              <td>6 SL</td>
              {
                //<td>A. Coulin, U. Lötscher, K. Schmid</td>
              }
            </tr>
            <tr>
              <th scope="row">09</th>
              <Link to="/route-info/S.i.G.">
                <td>S.i.G.</td>
              </Link>
              <td>6a (6a obl.)</td>
              <td>200 m</td>
              <td>6 SL</td>
              {
                //<td>M. Keller, U. Lötscher, K. Schmid</td>
              }
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>Projekt Keiser/Speck/Waser</td>
              <td>?</td>
              <td>? m</td>
              <td>? SL</td>
              {
                //<td></td>
              }
            </tr>
            <tr>
              <th scope="row">11</th>
              <td>Projekt Henseler/Speck</td>
              <td>?</td>
              <td>? m</td>
              <td>? SL</td>
              {
                //<td></td>
              }
            </tr>
            <tr>
              <th scope="row">12</th>
              <Link to="/route-info/Dä Burner">
                <td>Dä Burner</td>
              </Link>
              <td>6c+ (6b obl.) </td>
              <td>140 m</td>
              <td>5 SL</td>
              {
                //<td>S. Speck</td>
              }
            </tr>
            <tr>
              <th scope="row">13</th>
              <Link to="/route-info/Jesi">
                <td>Jesi</td>
              </Link>
              <td>6a</td>
              <td>190 m</td>
              <td>5 SL</td>
              {
                //<td>C. Matthis, D. Perret, S. Speck</td>
              }
            </tr>
            <tr>
              <th scope="row">14</th>
              <Link to="/route-info/Armada">
                <td>Armada</td>
              </Link>
              <td>7a+</td>
              <td>? m</td>
              <td>6 SL</td>
              {
                //<td></td>
              }
            </tr>
            <tr>
              <th scope="row">15</th>
              <td>Pasta zum Frühstück</td>
              <td>7a</td>
              <td>? m</td>
              <td>6 SL</td>
              {
                //<td></td>
              }
            </tr>
            <tr>
              <th scope="row">16</th>
              <Link to="/route-info/Schwerigi Sieche">
                <td>Schwerigi Sieche</td>
              </Link>
              <td>6c</td>
              <td>200 m</td>
              <td>6-7 SL</td>
              {
                //<td></td>
              }
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Wallpicture;
