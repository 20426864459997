import "./App.css";
import React from "react";
import Navbar from "./components/Homepage/Navbar.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home.jsx";
import RouteInfo from "./components/pages/RouteInfo.jsx";
import Biwak from "./components/pages/Biwak.jsx";
import Wallpicture from "./components/pages/Wallpicture.jsx";
import ScrollToTop from "./ScrollToTop.jsx";
export const AuthContext = React.createContext();

function App() {
  const [dispatch] = React.useReducer();
  return (
    <>
      <AuthContext.Provider
        value={{
          dispatch,
        }}
      >
        <Router>
          <ScrollToTop>
            <Navbar />
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/biwak" exact element={<Biwak />} />
              <Route path="/route-info/:id" exact element={<RouteInfo />} />
              <Route path="/wallpicture" exact element={<Wallpicture />} />
            </Routes>
          </ScrollToTop>
        </Router>
      </AuthContext.Provider>
    </>
  );
}

export default App;
