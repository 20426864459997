import React, {useEffect, useState} from "react";
import "../style/SignIn.css";
import Axios from "axios";
import {useNavigate} from "react-router-dom";
import "../style/Login.css";
import PropTypes from "prop-types";

export default function Login({setToken}) {
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState([]);

    useEffect(() => {
        Axios.get("https://barglen-project.onrender.com/api/logindata").then((response) => {
                setLoginData(response.data);
            }
        );
    }, []);

    const initialState = {
        username: "",
        password: "",
        isSubmitting: false,
        errorMessage: null,
    };
    const [data, setData] = React.useState(initialState);
    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null,
        });

        if (
            data.username === loginData[0].username &&
            data.password === loginData[0].password
        ) {
            setToken(process.env.REACT_APP_ACCESS_TOKEN);
            navigate("/biwak");
            window.location.reload();
        } else {
            setData({
                isSubmitting: false,
                errorMessage: "wrong username or password",
            });
        }
    };
    return (
        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />
                <form onSubmit={handleFormSubmit} autoComplete="off">
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            className="form-control"
                            name="username"
                            id="username"
                            value={data.username}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            value={data.password}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary btn-block submitBtn">
                            <span>Login</span>
                        </button>
                    </div>

                    {data.errorMessage && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {data.errorMessage}
                            </div>
                        </div>
                    )}
                    <button style={{display: "none"}}/>
                </form>
            </div>
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
};