import React, { useState } from "react";
import "../../App.css";
import Calendar from "../Calendar/Calendar.jsx";
import ClimbingTable from "../BiwakTable/ClimbingTable.jsx";
import Table from "../BiwakTable/Table.jsx";
import Login from "./Login";

function setToken(userToken) {
  sessionStorage.setItem("token", userToken);
}

function getToken() {
  const tokenString = sessionStorage.token;
  return tokenString;
}

function Biwak() {
  const [showClimbingstuff, setshowClimbingstuff] = useState(true);
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
    setshowClimbingstuff((current) => !current);
  };

  const token = getToken();
  if (!token) {
    return <Login setToken={setToken} />;
  }
  return (
    <>
      <div className="align-center">
        <button
          type="submit"
          className="btn btn-primary switch-button"
          onClick={handleClick}
        >
          {!isShown ? "Klettersachen" : "Allgemeine Sachen"}
        </button>
      </div>
      {isShown && <Table />} {showClimbingstuff && <ClimbingTable />}
      <Calendar />
    </>
  );
}

export default Biwak;
