import React from "react";
import '../../App.css'

function ReadOnlyRow({ item, handleEditClick, handleDeleteClick}) { 
  return (
    <tr key={item.id}>
      <td>{item.item}</td>
      <td>{item.amount}</td>
      <td>
      <i className="fa-solid fa-pen-to-square space" onClick={(event)=> handleEditClick(event, item)}></i>
      <i className="fa-solid fa-trash" onClick={()=> handleDeleteClick(item.id)}></i>
      </td>
    </tr>
  );
}

export default ReadOnlyRow;
