import React from "react";
import "../../App.css";

function ReadOnlyRow({ data, handleEditClick, handleDeleteClick }) {
  return (
    <tr>
      <td>{data.startDate}</td>
      <td>{data.endDate}</td>
      <td>{data.title}</td>
      <td>
        <i
          className="fa-solid fa-pen-to-square space"
          onClick={(event) => handleEditClick(event, data)}
        ></i>
        <i
          className="fa-solid fa-trash"
          onClick={() => handleDeleteClick(data.id)}
        ></i>
      </td>
    </tr>
  );
}

export default ReadOnlyRow;
