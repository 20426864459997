import React from "react";
import "../style/Table.css";

const EditableRow = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
  handleCheckboxChange,
  
}) => {
  return (
    <tr key={editFormData.id}>
      <td>
        <input
          type="text"
          className="form-control"
          required="required"
          name="item"
          placeholder="Enter a Item..."
          value={editFormData.item}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          required="required"
          name="amount"
          placeholder="Enter a Amount..."
          value={editFormData.amount}
          onChange={handleEditFormChange}
        ></input>
      </td>
      <td>
        <input
          type="checkbox"
          name="inUse"
          defaultChecked={editFormData.inUse}
          onChange={e => handleCheckboxChange(e)}
        />
      </td>
      <td>
        <button type="submit" className="btnEdit">
          <i className="fa-solid fa-check space"> </i>
        </button>
        <button type="button" onClick={handleCancelClick} className="btnEdit">
          <i className="fa-solid fa-xmark"> </i>
        </button>
      </td>
    </tr>
  );
};

export default EditableRow;
