import React, {Fragment, useEffect, useState} from "react";
import "../style/Table.css";
import "../../App.css";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import Axios from "axios";

function ClimbingTable() {
    //-------------------------------------------------------------------------------
    // state
    //-------------------------------------------------------------------------------

    const [tableData, setTableData] = useState([]);

    const [addFormData, setAddFormData] = useState({
        id: "",
        item: "",
        amount: "",
    });

    const [editFormData, setEditFormData] = useState({
        id: "",
        item: "",
        amount: "",
        inUse: "",
    });

    const [editItemId, setEditItemId] = useState(null);

    useEffect(() => {
        Axios.get("https://barglen-project.onrender.com/api/climbingstuff").then(
            (response) => {
                setTableData(response.data);
            }
        );
    }, []);

    //-------------------------------------------------------------------------------
    // handle ADD
    //-------------------------------------------------------------------------------

    const handleAddFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = {...addFormData};
        newFormData[fieldName] = fieldValue;

        setAddFormData(newFormData);
    };

    //-------------------------------------------------------------------------------
    // handle EDIT
    //-------------------------------------------------------------------------------

    const handleEditFormChange = (event) => {
        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = {...editFormData};
        newFormData[fieldName] = fieldValue;

        setEditFormData(newFormData);
    };

    const handleCheckboxChange = (event) => {
        const fieldValue = event.target.checked;

        const newFormData = {...editFormData};
        newFormData['inUse'] = fieldValue;

        setEditFormData(newFormData);
    };

    const submitItems = (e) => {
        e.preventDefault();
        try {
            Axios.post("https://barglen-project.onrender.com/api/climbingstuff", {
                item: addFormData.item,
                amount: addFormData.amount,
            });
            setTableData([
                ...tableData,
                {item: addFormData.item, amount: addFormData.amount},
            ]);
        } catch (error) {
            console.error(error);
        }
    };

    const handleEditSave = (event) => {
        event.preventDefault();

        if (editFormData.inUse === 'on') {
            editFormData.inUse = 1;
        }

        const editedItem = {
            id: editFormData.id,
            item: editFormData.item,
            amount: editFormData.amount,
            inUse: editFormData.inUse,
        };

        try {
            Axios.patch("https://barglen-project.onrender.com/api/climbingstuff", {
                id: editFormData.id,
                item: editedItem.item,
                amount: editedItem.amount,
                inUse: editFormData.inUse,
            });
            setTableData([
                ...tableData,
                {
                    item: addFormData.item,
                    amount: addFormData.amount,
                    inUse: addFormData.inUse,
                },
            ]);
        } catch (error) {
            console.error(error);
        }

        const newItems = [...tableData];
        const index = tableData.findIndex((item) => item.id === editItemId);
        newItems[index] = editedItem;
        setTableData(newItems);
        setEditItemId(null);
    };

    const handleEditClick = (event, item) => {
        event.preventDefault();
        setEditItemId(item.id);

        const formValues = {
            id: item.id,
            item: item.item,
            amount: item.amount,
            inUse: item.inUse,
        };

        setEditFormData(formValues);
    };

    const handleCancelClick = () => {
        setEditItemId(null);
    };

    //-------------------------------------------------------------------------------
    // handle DELETE
    //-------------------------------------------------------------------------------

    const handleDeleteClick = (id) => {
        try {
            const newItems = [...tableData];

            const index = tableData.findIndex((item) => item.id === id);
            Axios.delete("https://barglen-project.onrender.com/api/climbingstuff", {
                headers: {},
                data: {
                    id: id,
                }
            });
            newItems.splice(index, 1);
            setTableData(newItems);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <div className="table-container">
                <form onSubmit={handleEditSave} autoComplete="off">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Item</th>
                            <th scope="col">Amount</th>
                            <th scope="col">inUse</th>
                            <th scope="col">Edit</th>
                        </tr>
                        </thead>
                        <tbody className="table-group-divider">
                        {tableData.map((item) => (
                            <Fragment>
                                {editItemId === item.id ? (
                                    <EditableRow
                                        editFormData={editFormData}
                                        handleEditFormChange={handleEditFormChange}
                                        handleCancelClick={handleCancelClick}
                                        handleCheckboxChange={handleCheckboxChange}
                                    />
                                ) : (
                                    <ReadOnlyRow
                                        item={item}
                                        handleEditClick={handleEditClick}
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                )}
                            </Fragment>
                        ))}
                        </tbody>
                    </table>
                </form>

                <h2>Add Item</h2>
                <form onSubmit={submitItems} autoComplete="off">
                    <div className="row g-3">
                        <div className="col-8">
                            <label htmlFor="addItem" className="form-label form-label-lg">
                                Item
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="addItem"
                                name="item"
                                aria-label="item"
                                required="required"
                                onChange={handleAddFormChange}
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="addAmount" className="form-label form-label-lg">
                                Amount
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="addAmount"
                                name="amount"
                                aria-label="amount"
                                required="required"
                                onChange={handleAddFormChange}
                            />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-secondary">
                        Add
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ClimbingTable;
