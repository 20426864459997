import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../style/Navbar.css";

function Navbar() {
  const [clicked, setClick] = useState(false);
  const handleClick = () => setClick(!clicked);

  return (
    <>
      <nav>
        <div className="logo">
          <a href="/">
            <svg
              id="logo-13"
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.98441 29.2899C1.98441 27.0299 2.42954 24.7919 3.29444 22.704C4.15935 20.6159 5.42701 18.7187 7.02513 17.1206C8.62324 15.5225 10.5204 14.2548 12.6084 13.3899C14.6965 12.5251 16.9344 12.0799 19.1945 12.0799V29.2899H1.98441Z"
                className="ccustom"
                fill="#442781"
              ></path>
              <path
                d="M1.98441 29.2899C1.98441 31.55 2.42954 33.7879 3.29444 35.876C4.15935 37.964 5.42701 39.8612 7.02513 41.4593C8.62324 43.0574 10.5204 44.3251 12.6084 45.19C14.6965 46.0549 16.9344 46.5 19.1945 46.5V29.2899H1.98441Z"
                className="ccompli1"
                fill="#61459C"
              ></path>
              <path
                d="M36.4043 29.2899C36.4043 31.55 35.9595 33.7879 35.0947 35.876C34.2298 37.964 32.9622 39.8612 31.3638 41.4593C29.7657 43.0574 27.8685 44.3251 25.7804 45.19C23.6925 46.0549 21.4545 46.5 19.1945 46.5V29.2899H36.4043Z"
                className="ccompli2"
                fill="#A992DB"
              ></path>
              <path
                d="M1.98441 29.2899C1.4043 27.0299 35.9595 24.7919 35.0947 22.704C4.2298 20.6159 32.9622 18.7187 31.3638 17.1206C8.7657 15.5225 27.8685 14.2548 25.7804 13.3899C14.6925 12.5251 21.4545 12.0799 19.1945 12.0799V29.2899H36.4043Z"
                className="ccustom"
                fill="#cd9fed"
              ></path>
            </svg>
          </a>
        </div>
        <div className="nav-menu">
          <ul id="navbar" className={clicked ? "active" : ""}>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => {
                  return isActive ? "active" : "";
                }}
                onClick={() => setClick(false)}
              >
                Home
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/wallpicture"
                className={({ isActive }) => {
                  return isActive ? "active" : "";
                }}
                onClick={() => setClick(false)}
              >
                Routen
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/biwak"
                className={({ isActive }) => {
                  return isActive ? "active" : "";
                }}
                onClick={() => setClick(false)}
              >
                Intern
              </NavLink>
              </li>
          </ul>
        </div>
        <div id="mobile" onClick={handleClick}>
          <i id="bar" className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
