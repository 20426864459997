import React from "react";
import "../style/HeroSection.css";

function NewHeroSection() {
  return (
      <div class="header">
        <div class="sides"></div>
        <div class="sides"></div>
        <div class="headerInfo">
          <h4>
            <a href="#category">KLETTERGEBIET </a>
          </h4>
          <h1>BARGLEN</h1>
        </div>
      </div>
  );
}

export default NewHeroSection;